@tailwind base;
@tailwind components;
@tailwind utilities;

.overlay {
  @apply absolute inset-0 z-50 flex items-center justify-center bg-neutral-900 bg-opacity-80 pb-14;
}

.t {
  min-height: 25vh;
  min-width: 80vw;
  z-index: 1000;
}

.terms-condition {
  min-height: 50vh;
  min-width: 90vw;
  z-index: 1000;
  max-height: 82vh;
  overflow: scroll;
}

.overlay {
  @apply fixed inset-0 z-50 flex items-center justify-center bg-neutral-900 bg-opacity-80 pb-14 h-fit;
}

@layer components {
  .btn {
    @apply flex items-center justify-center w-full pt-2 pb-2 pl-4 pr-4 space-x-2 font-medium text-center bg-blue-600 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed;
  }

  .custom__btn {
    @apply flex items-center justify-center px-1 text-sm text-center transition-all ease-in-out border rounded-sm outline-none border-borderGray hover:opacity-100 focus:opacity-90 disabled:cursor-not-allowed disabled:opacity-30 hover:cursor-pointer;
  }
}

@font-face {
  font-family: notoSans;
  src: url("/public/assets/fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: notoItalic;
  src: url("/public/assets/fonts/NotoSans-Italic.ttf");
}

@layer base {
  html {
    font-family: notoSans;
  }
}

@media (display-mode: standalone), (display-mode: minimal-ui) {
  #pwa-installer-prompt {
    display: none;
  }
}

.map-container {
  width: 100%;
  height: 30vh;
}

.custom-scroll {
  height: 100vh;
  padding-bottom: 8rem;
  overflow: auto;
  webkit-overflow-scrolling: touch !important;
  -webkit-overflow-scrolling: touch !important;
}

ul li {
  list-style-type: disc;
}

ol li {
  list-style-type: decimal;
}

.divider-border {
  border: 1px solid #ccc;
}



.article-content > ul {
  padding: 1rem !important;
}
.article-content > ol {
  padding: 1rem !important;
}
